/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AddVoucherCodeResponseModelResult = {
    Success: 'Success',
    Warning: 'Warning',
    Error: 'Error'
} as const;
export type AddVoucherCodeResponseModelResult = typeof AddVoucherCodeResponseModelResult[keyof typeof AddVoucherCodeResponseModelResult];


export function AddVoucherCodeResponseModelResultFromJSON(json: any): AddVoucherCodeResponseModelResult {
    return AddVoucherCodeResponseModelResultFromJSONTyped(json, false);
}

export function AddVoucherCodeResponseModelResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddVoucherCodeResponseModelResult {
    return json as AddVoucherCodeResponseModelResult;
}

export function AddVoucherCodeResponseModelResultToJSON(value?: AddVoucherCodeResponseModelResult | null): any {
    return value as any;
}

