/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderDetailProductApiModel } from './OrderDetailProductApiModel';
import {
    OrderDetailProductApiModelFromJSON,
    OrderDetailProductApiModelFromJSONTyped,
    OrderDetailProductApiModelToJSON,
} from './OrderDetailProductApiModel';

/**
 * 
 * @export
 * @interface OrderDetailApiModel
 */
export interface OrderDetailApiModel {
    /**
     * 
     * @type {string}
     * @memberof OrderDetailApiModel
     */
    shippingMethod?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailApiModel
     */
    orderedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailApiModel
     */
    subTotal?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailApiModel
     */
    vat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailApiModel
     */
    shippingTotal?: number | null;
    /**
     * 
     * @type {Array<OrderDetailProductApiModel>}
     * @memberof OrderDetailApiModel
     */
    products?: Array<OrderDetailProductApiModel> | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailApiModel
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDetailApiModel
     */
    orderNumber?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderDetailApiModel
     */
    orderDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof OrderDetailApiModel
     */
    orderTotal?: number | null;
}

/**
 * Check if a given object implements the OrderDetailApiModel interface.
 */
export function instanceOfOrderDetailApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrderDetailApiModelFromJSON(json: any): OrderDetailApiModel {
    return OrderDetailApiModelFromJSONTyped(json, false);
}

export function OrderDetailApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDetailApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shippingMethod': !exists(json, 'shippingMethod') ? undefined : json['shippingMethod'],
        'orderedBy': !exists(json, 'orderedBy') ? undefined : json['orderedBy'],
        'subTotal': !exists(json, 'subTotal') ? undefined : json['subTotal'],
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
        'shippingTotal': !exists(json, 'shippingTotal') ? undefined : json['shippingTotal'],
        'products': !exists(json, 'products') ? undefined : (json['products'] === null ? null : (json['products'] as Array<any>).map(OrderDetailProductApiModelFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'orderNumber': !exists(json, 'orderNumber') ? undefined : json['orderNumber'],
        'orderDate': !exists(json, 'orderDate') ? undefined : (json['orderDate'] === null ? null : new Date(json['orderDate'])),
        'orderTotal': !exists(json, 'orderTotal') ? undefined : json['orderTotal'],
    };
}

export function OrderDetailApiModelToJSON(value?: OrderDetailApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shippingMethod': value.shippingMethod,
        'orderedBy': value.orderedBy,
        'subTotal': value.subTotal,
        'vat': value.vat,
        'shippingTotal': value.shippingTotal,
        'products': value.products === undefined ? undefined : (value.products === null ? null : (value.products as Array<any>).map(OrderDetailProductApiModelToJSON)),
        'id': value.id,
        'orderNumber': value.orderNumber,
        'orderDate': value.orderDate === undefined ? undefined : (value.orderDate === null ? null : value.orderDate.toISOString()),
        'orderTotal': value.orderTotal,
    };
}

