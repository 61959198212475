/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetFavoriteLocationResponseModel,
  LocationCardApiModel,
  LocationDetailApiModel,
  ProblemDetails,
  RemoveFavoriteLocationResponseModel,
  SetFavoriteLocationResponseModel,
} from '../models';
import {
    GetFavoriteLocationResponseModelFromJSON,
    GetFavoriteLocationResponseModelToJSON,
    LocationCardApiModelFromJSON,
    LocationCardApiModelToJSON,
    LocationDetailApiModelFromJSON,
    LocationDetailApiModelToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RemoveFavoriteLocationResponseModelFromJSON,
    RemoveFavoriteLocationResponseModelToJSON,
    SetFavoriteLocationResponseModelFromJSON,
    SetFavoriteLocationResponseModelToJSON,
} from '../models';

export interface LocationsFavoritePutRequest {
    id?: string;
}

export interface LocationsIdCardGetRequest {
    id: string;
}

export interface LocationsIdGetRequest {
    id: string;
}

/**
 * 
 */
export class LocationsApi extends runtime.BaseAPI {

    /**
     */
    async locationsFavoriteDeleteRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RemoveFavoriteLocationResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Locations/Favorite`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RemoveFavoriteLocationResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async locationsFavoriteDelete(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RemoveFavoriteLocationResponseModel> {
        const response = await this.locationsFavoriteDeleteRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async locationsFavoriteGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetFavoriteLocationResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Locations/Favorite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetFavoriteLocationResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async locationsFavoriteGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetFavoriteLocationResponseModel> {
        const response = await this.locationsFavoriteGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async locationsFavoritePutRaw(requestParameters: LocationsFavoritePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SetFavoriteLocationResponseModel>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Locations/Favorite`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SetFavoriteLocationResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async locationsFavoritePut(requestParameters: LocationsFavoritePutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SetFavoriteLocationResponseModel> {
        const response = await this.locationsFavoritePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async locationsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LocationCardApiModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Locations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LocationCardApiModelFromJSON));
    }

    /**
     */
    async locationsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LocationCardApiModel>> {
        const response = await this.locationsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async locationsIdCardGetRaw(requestParameters: LocationsIdCardGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LocationCardApiModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling locationsIdCardGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Locations/{id}/Card`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationCardApiModelFromJSON(jsonValue));
    }

    /**
     */
    async locationsIdCardGet(requestParameters: LocationsIdCardGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LocationCardApiModel> {
        const response = await this.locationsIdCardGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async locationsIdGetRaw(requestParameters: LocationsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LocationDetailApiModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling locationsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Locations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LocationDetailApiModelFromJSON(jsonValue));
    }

    /**
     */
    async locationsIdGet(requestParameters: LocationsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LocationDetailApiModel> {
        const response = await this.locationsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
