/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddVoucherCodeResponseModel,
  BasketApiModel,
  ProblemDetails,
  RemoveVoucherCodeResponseModel,
  UpdateBasketResponseModel,
} from '../models';
import {
    AddVoucherCodeResponseModelFromJSON,
    AddVoucherCodeResponseModelToJSON,
    BasketApiModelFromJSON,
    BasketApiModelToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    RemoveVoucherCodeResponseModelFromJSON,
    RemoveVoucherCodeResponseModelToJSON,
    UpdateBasketResponseModelFromJSON,
    UpdateBasketResponseModelToJSON,
} from '../models';

export interface BasketsBasketIdAddProductPostRequest {
    basketId: string;
    sku?: string;
    quantity?: number;
}

export interface BasketsBasketIdAddProductsPostRequest {
    basketId: string;
    requestBody?: { [key: string]: number; };
}

export interface BasketsBasketIdExecuteBasketPipelinePostRequest {
    basketId: string;
}

export interface BasketsBasketIdOrderLinesDeleteRequest {
    basketId: string;
}

export interface BasketsBasketIdOrderLinesOrderLineIdDeleteRequest {
    basketId: string;
    orderLineId: number;
}

export interface BasketsBasketIdShipmentPutRequest {
    basketId: string;
    shippingMethodId?: number;
    countryId?: number;
}

export interface BasketsBasketIdUpdateQuantityPostRequest {
    basketId: string;
    orderLineId?: number;
    newQuantity?: number;
}

export interface BasketsBasketIdVoucherCodeCodeDeleteRequest {
    basketId: string;
    code: string;
}

export interface BasketsBasketIdVoucherCodeCodePutRequest {
    basketId: string;
    code: string;
}

export interface BasketsGetCorrectBasketAfterLoginGetRequest {
    appBasketId?: string;
}

export interface BasketsIdGetRequest {
    id: string;
}

export interface BasketsMemberPutRequest {
    id?: string;
}

/**
 * 
 */
export class BasketsApi extends runtime.BaseAPI {

    /**
     */
    async basketsBasketIdAddProductPostRaw(requestParameters: BasketsBasketIdAddProductPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateBasketResponseModel>> {
        if (requestParameters.basketId === null || requestParameters.basketId === undefined) {
            throw new runtime.RequiredError('basketId','Required parameter requestParameters.basketId was null or undefined when calling basketsBasketIdAddProductPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.sku !== undefined) {
            queryParameters['sku'] = requestParameters.sku;
        }

        if (requestParameters.quantity !== undefined) {
            queryParameters['quantity'] = requestParameters.quantity;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Baskets/{basketId}/AddProduct`.replace(`{${"basketId"}}`, encodeURIComponent(String(requestParameters.basketId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateBasketResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async basketsBasketIdAddProductPost(requestParameters: BasketsBasketIdAddProductPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateBasketResponseModel> {
        const response = await this.basketsBasketIdAddProductPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async basketsBasketIdAddProductsPostRaw(requestParameters: BasketsBasketIdAddProductsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BasketApiModel>> {
        if (requestParameters.basketId === null || requestParameters.basketId === undefined) {
            throw new runtime.RequiredError('basketId','Required parameter requestParameters.basketId was null or undefined when calling basketsBasketIdAddProductsPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/Baskets/{basketId}/AddProducts`.replace(`{${"basketId"}}`, encodeURIComponent(String(requestParameters.basketId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BasketApiModelFromJSON(jsonValue));
    }

    /**
     */
    async basketsBasketIdAddProductsPost(requestParameters: BasketsBasketIdAddProductsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BasketApiModel> {
        const response = await this.basketsBasketIdAddProductsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async basketsBasketIdExecuteBasketPipelinePostRaw(requestParameters: BasketsBasketIdExecuteBasketPipelinePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BasketApiModel>> {
        if (requestParameters.basketId === null || requestParameters.basketId === undefined) {
            throw new runtime.RequiredError('basketId','Required parameter requestParameters.basketId was null or undefined when calling basketsBasketIdExecuteBasketPipelinePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Baskets/{basketId}/ExecuteBasketPipeline`.replace(`{${"basketId"}}`, encodeURIComponent(String(requestParameters.basketId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BasketApiModelFromJSON(jsonValue));
    }

    /**
     */
    async basketsBasketIdExecuteBasketPipelinePost(requestParameters: BasketsBasketIdExecuteBasketPipelinePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BasketApiModel> {
        const response = await this.basketsBasketIdExecuteBasketPipelinePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async basketsBasketIdOrderLinesDeleteRaw(requestParameters: BasketsBasketIdOrderLinesDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BasketApiModel>> {
        if (requestParameters.basketId === null || requestParameters.basketId === undefined) {
            throw new runtime.RequiredError('basketId','Required parameter requestParameters.basketId was null or undefined when calling basketsBasketIdOrderLinesDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Baskets/{basketId}/OrderLines`.replace(`{${"basketId"}}`, encodeURIComponent(String(requestParameters.basketId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BasketApiModelFromJSON(jsonValue));
    }

    /**
     */
    async basketsBasketIdOrderLinesDelete(requestParameters: BasketsBasketIdOrderLinesDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BasketApiModel> {
        const response = await this.basketsBasketIdOrderLinesDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async basketsBasketIdOrderLinesOrderLineIdDeleteRaw(requestParameters: BasketsBasketIdOrderLinesOrderLineIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BasketApiModel>> {
        if (requestParameters.basketId === null || requestParameters.basketId === undefined) {
            throw new runtime.RequiredError('basketId','Required parameter requestParameters.basketId was null or undefined when calling basketsBasketIdOrderLinesOrderLineIdDelete.');
        }

        if (requestParameters.orderLineId === null || requestParameters.orderLineId === undefined) {
            throw new runtime.RequiredError('orderLineId','Required parameter requestParameters.orderLineId was null or undefined when calling basketsBasketIdOrderLinesOrderLineIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Baskets/{basketId}/OrderLines/{orderLineId}`.replace(`{${"basketId"}}`, encodeURIComponent(String(requestParameters.basketId))).replace(`{${"orderLineId"}}`, encodeURIComponent(String(requestParameters.orderLineId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BasketApiModelFromJSON(jsonValue));
    }

    /**
     */
    async basketsBasketIdOrderLinesOrderLineIdDelete(requestParameters: BasketsBasketIdOrderLinesOrderLineIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BasketApiModel> {
        const response = await this.basketsBasketIdOrderLinesOrderLineIdDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async basketsBasketIdShipmentPutRaw(requestParameters: BasketsBasketIdShipmentPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BasketApiModel>> {
        if (requestParameters.basketId === null || requestParameters.basketId === undefined) {
            throw new runtime.RequiredError('basketId','Required parameter requestParameters.basketId was null or undefined when calling basketsBasketIdShipmentPut.');
        }

        const queryParameters: any = {};

        if (requestParameters.shippingMethodId !== undefined) {
            queryParameters['shippingMethodId'] = requestParameters.shippingMethodId;
        }

        if (requestParameters.countryId !== undefined) {
            queryParameters['countryId'] = requestParameters.countryId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Baskets/{basketId}/Shipment`.replace(`{${"basketId"}}`, encodeURIComponent(String(requestParameters.basketId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BasketApiModelFromJSON(jsonValue));
    }

    /**
     */
    async basketsBasketIdShipmentPut(requestParameters: BasketsBasketIdShipmentPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BasketApiModel> {
        const response = await this.basketsBasketIdShipmentPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async basketsBasketIdUpdateQuantityPostRaw(requestParameters: BasketsBasketIdUpdateQuantityPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateBasketResponseModel>> {
        if (requestParameters.basketId === null || requestParameters.basketId === undefined) {
            throw new runtime.RequiredError('basketId','Required parameter requestParameters.basketId was null or undefined when calling basketsBasketIdUpdateQuantityPost.');
        }

        const queryParameters: any = {};

        if (requestParameters.orderLineId !== undefined) {
            queryParameters['orderLineId'] = requestParameters.orderLineId;
        }

        if (requestParameters.newQuantity !== undefined) {
            queryParameters['newQuantity'] = requestParameters.newQuantity;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Baskets/{basketId}/UpdateQuantity`.replace(`{${"basketId"}}`, encodeURIComponent(String(requestParameters.basketId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateBasketResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async basketsBasketIdUpdateQuantityPost(requestParameters: BasketsBasketIdUpdateQuantityPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateBasketResponseModel> {
        const response = await this.basketsBasketIdUpdateQuantityPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async basketsBasketIdVoucherCodeCodeDeleteRaw(requestParameters: BasketsBasketIdVoucherCodeCodeDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RemoveVoucherCodeResponseModel>> {
        if (requestParameters.basketId === null || requestParameters.basketId === undefined) {
            throw new runtime.RequiredError('basketId','Required parameter requestParameters.basketId was null or undefined when calling basketsBasketIdVoucherCodeCodeDelete.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling basketsBasketIdVoucherCodeCodeDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Baskets/{basketId}/VoucherCode/{code}`.replace(`{${"basketId"}}`, encodeURIComponent(String(requestParameters.basketId))).replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RemoveVoucherCodeResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async basketsBasketIdVoucherCodeCodeDelete(requestParameters: BasketsBasketIdVoucherCodeCodeDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RemoveVoucherCodeResponseModel> {
        const response = await this.basketsBasketIdVoucherCodeCodeDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async basketsBasketIdVoucherCodeCodePutRaw(requestParameters: BasketsBasketIdVoucherCodeCodePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddVoucherCodeResponseModel>> {
        if (requestParameters.basketId === null || requestParameters.basketId === undefined) {
            throw new runtime.RequiredError('basketId','Required parameter requestParameters.basketId was null or undefined when calling basketsBasketIdVoucherCodeCodePut.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling basketsBasketIdVoucherCodeCodePut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Baskets/{basketId}/VoucherCode/{code}`.replace(`{${"basketId"}}`, encodeURIComponent(String(requestParameters.basketId))).replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddVoucherCodeResponseModelFromJSON(jsonValue));
    }

    /**
     */
    async basketsBasketIdVoucherCodeCodePut(requestParameters: BasketsBasketIdVoucherCodeCodePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddVoucherCodeResponseModel> {
        const response = await this.basketsBasketIdVoucherCodeCodePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async basketsGetCorrectBasketAfterLoginGetRaw(requestParameters: BasketsGetCorrectBasketAfterLoginGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BasketApiModel>> {
        const queryParameters: any = {};

        if (requestParameters.appBasketId !== undefined) {
            queryParameters['appBasketId'] = requestParameters.appBasketId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Baskets/GetCorrectBasketAfterLogin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BasketApiModelFromJSON(jsonValue));
    }

    /**
     */
    async basketsGetCorrectBasketAfterLoginGet(requestParameters: BasketsGetCorrectBasketAfterLoginGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BasketApiModel> {
        const response = await this.basketsGetCorrectBasketAfterLoginGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async basketsIdGetRaw(requestParameters: BasketsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BasketApiModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling basketsIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Baskets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BasketApiModelFromJSON(jsonValue));
    }

    /**
     */
    async basketsIdGet(requestParameters: BasketsIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BasketApiModel> {
        const response = await this.basketsIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async basketsMemberGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BasketApiModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Baskets/Member`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BasketApiModelFromJSON(jsonValue));
    }

    /**
     */
    async basketsMemberGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BasketApiModel> {
        const response = await this.basketsMemberGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async basketsMemberPutRaw(requestParameters: BasketsMemberPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Baskets/Member`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async basketsMemberPut(requestParameters: BasketsMemberPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.basketsMemberPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async basketsPutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BasketApiModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Baskets`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BasketApiModelFromJSON(jsonValue));
    }

    /**
     */
    async basketsPut(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BasketApiModel> {
        const response = await this.basketsPutRaw(initOverrides);
        return await response.value();
    }

}
