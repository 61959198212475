/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const OrderLineApiModelDeliveryTime = {
    Unknown: 'Unknown',
    Direct: 'Direct',
    Delayed: 'Delayed',
    NoDelivery: 'NoDelivery',
    StoreOnly: 'StoreOnly'
} as const;
export type OrderLineApiModelDeliveryTime = typeof OrderLineApiModelDeliveryTime[keyof typeof OrderLineApiModelDeliveryTime];


export function OrderLineApiModelDeliveryTimeFromJSON(json: any): OrderLineApiModelDeliveryTime {
    return OrderLineApiModelDeliveryTimeFromJSONTyped(json, false);
}

export function OrderLineApiModelDeliveryTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderLineApiModelDeliveryTime {
    return json as OrderLineApiModelDeliveryTime;
}

export function OrderLineApiModelDeliveryTimeToJSON(value?: OrderLineApiModelDeliveryTime | null): any {
    return value as any;
}

